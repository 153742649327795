.search-container {
    position: relative;
    width: 65%;
    height: 30px;
    margin-top: -3px !important;   
    
    @media (max-width: 1700px) {
        width: 50%;
    }
    
    @media (max-width: 1550px) {
        width: 40%;
    }
    
    @media (max-width: 1350px) {
        width: 50%;
    }

    @media (max-width: 1200px) {
        width: 90%;
    }
    
    @media (max-width: 768px) {
        width: 100%;
        height: 25px;
    }
}