.results-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    font-family: Geometria;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    border-radius: 30px;
    border: 1px solid #ddd;

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.results-list::-webkit-scrollbar {
    width: 8px;
}

.results-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.results-list::-webkit-scrollbar-thumb {
    background: #D6D6FA;
    border-radius: 10px;
}

.results-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
