.error-container {
    display: flex;
    font-family: Geometria;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    
    #error-image {
        width: 100px;
        height: 100px;
    }
    
    #error-title {
        margin-top: 25px;
        font-weight: 800;
        font-size: 38px;
        text-align: center;
        color: blue;
    }
    
    #error-text {
        margin-top: 15px;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
    }
    
    @media (max-width: 850px) {
        margin-top: 50px;
    }
}