.form {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  padding-top: 35px;
  padding-bottom: 80px;
}

@media screen and (max-width: 786px) {
  .form {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

[data-theme="dark"] .form {
  background: #000;
}

/* .formBlock {
  padding: 0 30px;
} */
