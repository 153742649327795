.container {
    width: 100px;
    height: 48px;
    border-radius: 30px;
    background: #EEF0F2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    font-family: Geometria;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 16px;
    overflow: hidden;
    cursor: pointer;
}

.first {
    border-right: #3330e4 1px solid;
    width: 17%;
    height: 100%;
    padding-top: 25px;
    padding-right: 10px;
}

.third {
    border-left: #3330e4 1px solid;
    width: 17%;
    height: 100%;
    padding-top: 25px;
    padding-left: 10px;
}

.selected {
    font-weight: 800;
}