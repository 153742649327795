.add-influencer-modal {
    padding: 40px 0;

    #genres-container {
        margin: 30px auto 0;
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 900px;

        #genre-button {
            min-width: 140px;
            max-width: 180px;
            height: 60px;
            padding: 10px;
            margin: 5px;
            border: 1px solid #3330E4;
            border-radius: 40px;
            cursor: pointer;
            transition: background-color 0.3s ease, box-shadow 0.3s ease;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-family: Geometria;
            font-size: 20px;
            font-weight: 700;
            text-align: center;

            &:hover {
                box-shadow: 2px 2px 3px #3330E480;
            }
        }

        .genre-button-selected {
            background-color: #3330E4;
            color: white;
        }
    }

    #search-container {
        margin: 40px auto 0;
        width: 400px;
    }
}

.result-block {
    width: 80%;
    margin: 30px auto 0;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.result-block.visible {
    opacity: 1;
    transform: translateY(0);
    
    #acc-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-username {
            margin-top: -45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            #sm-logo {
                display: flex;
                align-items: center;
                gap: 16px;

                #sm {
                    width: 35px;
                    height: 35px;
                }

                #logo {
                    width: 70px;
                    height: 70px;
                }
            }

            p {
                font-family: Geometria;
                font-size: 20px;
                font-weight: 700;
                text-align: left;
            }
        }

        .genres-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40%;

            p {
                font-family: Geometria;
                font-size: 16px;
                font-weight: 500;
                text-align: left;
            }
        }

        .price-followers {
            p {
                font-family: Geometria;
                font-size: 20px;
                font-weight: 700;
                text-align: left;
            }

            span {
                font-family: Geometria;
                font-size: 18px;
                font-weight: 500;
                text-align: left;
            }
        }
    }
    
    .add-influencer-button {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}