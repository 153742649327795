@import "./utils/reset";
@import "./utils/container";
@import "./utils/font";
@import "./utils/themes";
@import "./components/header";
@import "./components/signup-select";
@import "./components/signup-client";
@import "./components/account-client";
@import "./components/account-influencer";
@import "./components/login-client";
@import "./components/create-invoice";
@import "./components/invoices";
@import "./components/terms";
@import "./components/report";
@import "./components/invoice-result";
@import "./components/proposal-system";

.notification {
  font-family: 'Geometria' !important;
}

.back-btn-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;

  button {
    position: absolute;
    left: 0;
    margin-left: 10px;
    cursor: pointer;

    img {
      transform: rotate(180deg);
      width: 45px;
    }
  }

  @media screen and (max-width: 850px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 450px) {
    button {
      img {
        width: 30px;
      }
    }
  }
}