.social-media-horizontal-list {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-evenly; 
    gap: 10px; 
}

.social-media-horizontal-list-item {
    flex: 1 0 calc(100% / 7 - 10px);
    max-width: 200px; 
    height: 55px;
    display: flex;
    background: white;
    border-radius: 50px;
    align-items: center;
    padding: 0 0 0 15px;
    box-shadow: 0px 4px 20px 0px #3330E480;
    box-sizing: border-box;
    cursor: pointer;
    
    &:hover {
        transition: 0.5s;
        box-shadow: 0px 6px 25px 0px #FF7A0940;
        background: #FF7A09BD;
    }
    
    &:not(:hover) {
        transition: 0.5s;
    }
        
    &.selected {
        background: #FF7A09BD;
        box-shadow: 0px 6px 25px 0px #FF7A0940;
    }
}

.social-media-horizontal-list-item img {
    width: 35px; 
    height: 35px;
}

.social-media-horizontal-list-item p {
    font-family: Geometria;
    font-size: 18px; 
    font-weight: 700;
    text-align: center;
    margin-left: 10px;
}

@media (max-width: 1200px) {
    .social-media-horizontal-list-item {
        flex: 1 0 calc(100% / 5 - 20px);
    }
}

@media (max-width: 768px) {
    .social-media-horizontal-list-item {
        flex: 1 0 calc(100% / 3 - 15px);
    }
}

@media (max-width: 480px) {
    .social-media-horizontal-list-item {
        flex: 1 0 100%; 
    }
}
