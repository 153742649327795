.price-input {
    display: flex;
    justify-content: left;
    align-items: center;
    
    input {
        margin-top: 3px;
        height: 100%;
        width: auto;
        max-width: 10ch;
        font-family: Geometria;
        font-size: 24px;
        font-weight: 700;
        padding: 0 5px;
        text-align: left;
    }
}