.input-wrapper {
    background-color: white;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 1px solid #3330E4;
    align-items: center;

    input {
        background-color: transparent;
        margin-left: 4px;
        margin-bottom: 2px;
        height: 100%;
        font-family: Geometria;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        align-items: center;
        color: #000000;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    input:focus {
        outline: none;
    }

    input::placeholder {
        color: #000000;
        opacity: 1;
    }

    .search-icon {
        width: 20px;
        height: 20px;
        padding-left: 5px;
    }
}