body {
  overflow-x: hidden;
}

.account-client-menu-button-multipromo {
  margin: 60px auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 48px 0;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  cursor: pointer;
  transition: background 250ms linear;
  transition-property: background, box-shadow;

  &:hover {
    background: rgba(51, 48, 228, 0.1);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.7);
  }

  @media (max-width: 900px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 500px) {
    width: 75%;
    margin: 40px auto 0;
  }
}

.account-client-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100% !important;
  margin-top: 20px;
}

.account-client-home-contact-support-button {
  font-family: Geometria;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  font-size: 28px;
  width: 231px;
  margin: 65px auto 0;
}

.account-client-menu-item {
  width: calc((100% - 40px) / 3);

  @media screen and (max-width: 900px) {
    width: calc((100% - 40px) / 2);
    margin: 0 auto;
  }
  @media screen and (max-width: 500px) {
    width: 75%;
    margin: 0 auto;
  }
}

.account-client-menu-button {
  height: 202px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  cursor: pointer;
  transition: background 250ms linear;
  transition-property: background, box-shadow;


  &:hover {
    background: rgba(51, 48, 228, 0.1);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.7);
  }
}

.account-client-menu-item.coming-soon .account-client-menu-button {
  background: #E4E6EE;
  position: relative;
  cursor: default;

  &:hover {
    background: #E4E6EE;
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  }
}

.account-client-menu-item.coming-soon .account-client-menu-button::after {
  content: "Coming Soon";
  font-family: Geometria;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.64px;
  text-align: center;
  color: black;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: #3330E480;
  padding: 5px 10px;
  border-radius: 18.5px;
}

.account-client-menu-button-text {
  margin-top: 22px;
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 900px) {
    font-size: 24px;
  }
}

.account-client-menu-button-subtext {
  color: var(--text);
  font-family: Geometria;
  font-weight: 400;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
}

.account-client-title {
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
}

.account-client-second {
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 38px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 70px;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
}

.account-client-offers-block {
  margin: 25px 0 0 0;
  display: flex;
  justify-content: center;
}

.offers-list-container {
  display: flex;
  width: 100%;

  .account-client-offers-item {
    cursor: pointer;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 682px;
    background-color: white;
    max-width: 303px;
    border-radius: 30px;
    box-shadow: 0px 4px 20px 0px #3330E480;
    transition: background 250ms linear, box-shadow 250ms linear, filter 250ms linear, opacity 250ms linear, transform 150ms linear;

    .account-client-offers-title {
      margin-top: 30px;
      margin-bottom: 21px;
      font-family: Geometria;
      font-weight: 900;
      font-size: 26px;
      text-align: center;
      color: blue;
      transition: color 250ms linear;
    }

    .account-client-offers-text {
      font-family: Geometria;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }

    .account-client-offers-text-list {
      margin-top: 25px;
      height: 400px;
      overflow-y: auto;
      padding: 0 20px 0 20px;

      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 11px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #D6D6FA;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEAFC;
      }

      .account-client-offers-text-item {
        font-family: Geometria;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        gap: 5px;
      }
    }

    .account-client-offers-button {
      cursor: pointer;
      margin-top: 35px;
      height: 84px;
      border-radius: 0 0 30px 30px;
      width: 100%;
      background-color: blue;
      color: white;
      font-family: Geometria;
      font-weight: 900;
      font-size: 26px;
      text-align: center;
      transition: background 250ms linear, color 250ms linear;
    }

    &:hover {
      transform: scale(1.03);
    }

    &.active {
      background: #FF7A09BD;
      box-shadow: 0px 4px 20px 0px #FF7A09E5;

      .account-client-offers-title {
        color: black;
      }

      .account-client-offers-text-list {
        &::-webkit-scrollbar-thumb {
          background: #FF7A09;
        }

        &::-webkit-scrollbar-track {
          background: #FFFAF736;
        }
      }

      .account-client-offers-button {
        background: #FF7A09;
        color: black;
      }
    }

    &.not-active {
      filter: blur(2px);
      opacity: 0.9;
    }

    @media (max-width: 1200px) {
      margin-top: 20px;
    }

    @media (min-width: 900px) and (max-width: 1150px) {
      max-width: 250px;
    }

    @media (max-width: 768px) {
      margin-top: 20px;
      height: 450px;
      max-width: 230px;

      .account-client-offers-title {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .account-client-offers-text {
        font-size: 12px;
      }

      .account-client-offers-text-list {
        margin-top: 15px;
        height: 270px;

        .account-client-offers-text-item {
          font-size: 14px;
        }
      }

      .account-client-offers-button {
        margin-top: 15px;
        font-size: 20px;
        height: 50px;
      }
    }
  }
}

.account-client-pick-and-choose-container {
  margin: 35px auto 60px;
  display: flex;
  gap: 64px;

  @media (max-width: 1450px) {
    gap: 40px;
  }
  @media (max-width: 1400px) {
    &-left-side {
      width: 25% !important;
    }

    &-right-side {
      width: 75% !important;
    }
  }
  @media (max-width: 1100px) {
    &-left-side {
      width: 30% !important;
    }

    &-right-side {
      width: 70% !important;
    }
  }
  @media (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    gap: 10px;

    &-left-side {
      width: 100% !important;
    }
    &-right-side {
      width: 100% !important;
      margin-top: 30px;
    }
  }
  @media (max-width: 513px) {
    &-right-side {
      width: 80% !important;
      margin: 10px auto 0;
    }
  }

  &-left-side {
    width: 20%;
  }

  &-right-side {
    width: 80%;

    .filters-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      padding-right: 40px;

      .search-sort-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 30px;

        @media (max-width: 1200px) {
          margin-top: 47px;
          flex-direction: column;
          gap: 15px;
          width: 40%;
        }
      }
    }

    .account-client-choose-list {
      margin-top: 46px;
      padding-right: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 30px calc((100% - (5 * 300px)) / 4);

      @media (max-width: 2000px) {
        gap: 30px calc((100% - (4 * 300px)) / 3);
      }
      @media (max-width: 1650px) {
        gap: 30px calc((100% - (4 * 250px)) / 3);
      }
      @media (max-width: 1400px) {
        margin-top: 65px;
        gap: 30px calc((100% - (3 * 300px)) / 2);
      }
      @media (max-width: 1300px) {
        gap: 30px calc((100% - (3 * 250px)) / 2);
      }
      @media (max-width: 1100px) {
        gap: 30px calc((90% - (2 * 275px)) / 1);
        padding-right: 40px;
        justify-content: center;
      }
      @media (max-width: 950px) {
        gap: 30px calc((75% - (2 * 220px)) / 1);
        justify-content: space-between;
        padding-right: 100px;
        padding-left: 20px;
      }
      @media (max-width: 850px) {
        padding-right: 75px;
      }
      @media (max-width: 768px) {
        padding: 10px 0;
        margin: 0;
        justify-content: center;
        height: 100%;
      }

      .account-client-choose-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 162px;
        max-width: 300px;
        border-radius: 30px;
        background: var(--background);
        box-shadow: 0px 4px 20px 0px #3330E480;
        transition: background 250ms linear, height 250ms linear;
        cursor: default;
        z-index: 0;
        flex: 1 1 calc((20% - (5 * 300px) / 4));

        @media (max-width: 2000px) {
          flex: 1 1 calc((25% - (4 * 300px) / 3));
        }
        @media (max-width: 1650px) {
          max-width: 250px;
          flex: 1 1 calc((25% - (4 * 250px) / 3));
        }
        @media (max-width: 1400px) {
          max-width: 300px;
          flex: 1 1 calc((33.33% - (3 * 300px) / 2));
        }
        @media (max-width: 1300px) {
          max-width: 250px;
        }
        @media (max-width: 1100px) {
          max-width: 275px;
          flex: 1 1 calc((50% - (2 * 275px) / 1));
        }
        @media (max-width: 950px) {
          max-width: 220px;
          flex: 1 1 calc((50% - (2 * 220px) / 1));
        }

        &.active {
          background: #3330E480;
          box-shadow: 0px 4px 20px 0px #3330E4;
        }

        &.connect {
          background: #FF7A09BD;
          box-shadow: 0px 4px 20px 0px #FF7A09E5;
        }

        &.flipped {
          height: 325px;
          z-index: 1000;
        }

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }
}

.account-client-choose-all {
  &-block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.account-client-choose-total {
  margin-top: 35px;
  text-align: center;

  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  &-span {
    color: var(--text);
    font-family: Geometria;
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 20px;

    &-span {
      font-size: 20px;
    }
  }
}

.account-client-choose-item-content-price p {
  display: flex;
  align-items: center;
}

.account-client-choose-item-content-price span {
  font-weight: 900;
  font-size: 20px;
  margin-left: 8px;
  padding-bottom: 1px;
}

.account-client-choose-item-content-third-container button {
  background: none;
  border: none;
  color: var(--text);
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-family: Geometria;
  font-size: 18px;
  font-weight: 400;
  line-height: 18.87px;
  text-align: center;
}

.account-client-choose-item-content {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 81px;
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  background: #E5E5E5;

  &.active {
    background: #3330E480;
  }

  &.connect {
    background: #FF7A0980;
  }

  &.flipped {
    height: 81px;
    z-index: 1000;
  }
}

.account-client-choose-item-connect {
  opacity: 0;
  pointer-events: none;
  width: 80px;
  height: 32px;
  background: #0af483;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 100ms linear;

  &-text {
    text-align: center;
    user-select: none;
    font-size: 15px;
    font-family: Geometria;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--text);
  }
}

.account-client-choose-item-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: -25px;
}

.account-client-choose-item-content-username {
  color: var(--text);
  text-transform: uppercase;
  max-width: 140px;
  width: 146px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Geometria;
  font-size: 20px;
  font-weight: 700;
  line-height: 22.64px;
  text-align: left;
  margin: 15px 14px 9px 0;
}

.account-client-choose-item-content-second-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.account-client-choose-item-content-icon {
  width: 16px;
  height: 16px;
}

.account-client-choose-item-content-second-container-left-part {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.account-client-choose-item-content-text {
  color: var(--text);
  text-transform: uppercase;
  max-width: 120px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Geometria;
  font-size: 20px;
  font-weight: 700;
  line-height: 22.64px;
  text-align: left;
  margin-left: 6px;
  margin-right: 120px;
  flex-grow: 1;
}

.account-client-choose-item-content-price {
  display: flex;
  align-items: center;
  font-family: Geometria;
  font-size: 20px;
  font-weight: 500;
  margin-right: 20px;
  text-align: right;
  position: absolute;
  right: 0;
}

.account-client-choose-item-content-third-container {
  text-align: center;
  width: 100%;
  position: relative;
}

.account-client-choose-item-content-third-container .see-more-button {
  padding: 10px 0;
  background: none;
  color: var(--text);
  text-decoration: underline;
  cursor: pointer;
  font-family: Geometria;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 50ms linear;
  line-height: 1.5;
}

.account-client-choose-item-back {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.10s ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  font-family: Geometria;
  width: 100%;
  height: 100%;
  background: transparent;

  span {
    font-size: 20px;
    font-weight: 900;
    color: #3330E4;
  }
}

.account-client-choose-item-back.show {
  display: flex;
  opacity: 1;
}

.account-client-choose-item-back.connect {
  .account-client-choose-item-back-countries-title,
  .account-client-choose-item-back-genres-title {
    color: white;
  }
}

.account-client-choose-item-horizontal-line {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .account-client-choose-item-back-left-side {
    padding-left: 5px;
    padding-right: 5px;
  }

  .account-client-choose-item-back-right-side {
    padding-right: 5px;
  }
}

.account-client-choose-item-back-left-side,
.account-client-choose-item-back-right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.account-client-choose-item-back-left-side-countries,
.account-client-choose-item-back-right-side-genres {
  font-family: Geometria;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.87px;
}

.account-client-choose-item-back-left-side-country-percentage {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.account-client-choose-item-back-left-side-country-percentage li {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.account-client-choose-item-back-left-side-country-percentage .country-name {
  font-family: Geometria;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #000000;
}

.account-client-choose-item-back-left-side-country-percentage .country-percentage {
  font-family: Geometria;
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  margin-top: 2px;
  margin-left: 10px;
}

.account-client-choose-item-expanded-content .see-less-button {
  padding: 10px 0;
  background: none;
  color: var(--text);
  text-decoration: underline;
  cursor: pointer;
  font-family: Geometria;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  transition: opacity 100ms linear;
  margin: 0 !important;
}

.account-client-choose-item-expanded-content {
  margin-top: 10px;
  //background: var(--background);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.account-client-choose-item-back-right-side-genres {
  font-family: Geometria;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.87px;
  text-align: left;
}

.account-client-choose-item-back-right-side-genres ul {
  list-style-type: none;
  padding: 0;
}

.account-client-choose-item-back-right-side-genres li {
  margin-top: 7.5px;
}

.account-client-post {
  max-width: 665px;
  margin: 0 auto;

  @media screen and (max-width: 915px) {
    //margin: 0;
  }
}

.account-client-post-campaign-name {
  width: 50%;
  margin: 34px auto;

  p {
    font-family: Geometria;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 850px) {
    width: 70%;
  }

  @media screen and (max-width: 500px) {
    width: 80%;

    p {
      font-size: 18px;
    }
  }
}

.account-client-payment {
  margin: 15px auto 10px;
  width: 90%;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  overflow: hidden;

  &-header {
    background: #3330e4;
    padding: 25px 0;
    display: flex;
    justify-content: center;

    &-title {
      color: #fff;
      text-align: center;
      font-family: Geometria;
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 28px;
      }

      @media screen and (max-width: 550px) {
        font-size: 18px;
      }
    }
  }

  &-content {
    padding-top: 40px;
    padding-bottom: 50px;

    @media screen and (max-width: 768px) {
      padding: 40px 24px 50px 24px;
    }

    @media screen and (max-width: 550px) {
      padding: 40px 44px 50px 44px;
    }

    &-methods {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 32px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      &-item {
        width: calc((100% - 40px) / 3);
        max-width: 208px;
        height: 117px;

        @media screen and (max-width: 768px) {
          width: calc((100% - 20px) / 2);
        }

        @media screen and (max-width: 550px) {
          width: 100%;
        }
      }

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        border-radius: 30px;
        background: var(--background);
        box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);

        color: #3330e4;
        text-align: center;
        font-family: Geometria;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 100% */
        text-transform: uppercase;

        cursor: pointer;
        transition: box-shadow 250ms linear;

        &:hover {
          box-shadow: 0px 4px 20px 0px #3330e4;
        }
      }
    }

    &-company {
      max-width: 665px;
      margin: 0 auto;
      margin-top: 60px;

      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      }

      &-logo {
        opacity: 0.5;

        transition: opacity 250ms linear;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-select-transfer-data {
    &-title {
      font-size: 20px;
      font-weight: 700;
      color: var(--text);
      font-family: Geometria;
      font-style: normal;
      text-align: center;
    }

    &-value {
      font-size: 16px;
      font-weight: 500;
      color: var(--text);
      font-family: Geometria;
      font-style: normal;
      text-align: center;
    }

    &-warning {
      font-size: 16px;
      font-weight: 500;
      color: #ff7a09;
      font-family: Geometria;
      font-style: normal;
      text-align: center;
      margin-top: 15px;
    }
  }
}

.account-client-bank {
  padding: 63px 83px;

  &-flex {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    gap: 80px;
  }

  &-label {
    position: relative;
    display: flex;
    width: 100%;

    &-title {
      position: absolute;
      bottom: calc(100% + 17px);
      left: 0;

      color: #000;
      font-family: Geometria;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
    }
  }

  &-input {
    padding: 14px 0;
    width: 100%;

    border: none;
    border-bottom: 1px solid rgba(51, 48, 228, 0.5);

    color: var(--Gray, #808080);
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--Gray, #808080);
      font-family: Geometria;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.account-client-payment-po {
  &-container {
    padding-left: 87px;

    span {
      font-family: Geometria;
      font-size: 28px;
      font-weight: 700;
      color: #3330E4;
    }

    p {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 700;
      padding-top: 10px;
    }

    &-first {
      display: flex;
      gap: 27px;
      margin-top: 45px;

      > * {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .account-client-payment-po-container {
      padding-left: 0;
    }

    .account-client-payment-po-container-first {
      margin-top: 30px;
      margin-bottom: 30px;
      flex-direction: column;
      gap: 10px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    span {
      display: block;
    }
  }
}

.account-client-past-promos-second {
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.account-client-past-promos-form {
  padding: 40px 80px;

  @media (max-width: 900px) {
    padding: 30px 40px;
  }
  @media (max-width: 700px) {
    padding: 30px 20px;
  }
  @media (max-width: 400px) {
    padding: 30px 0;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    justify-content: start;

    @media (max-width: 900px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 800px) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 40px;
    }
    @media (max-width: 650px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 60px
    }
    @media (max-width: 600px) {
      column-gap: 40px;
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 60px;
    }
    @media (max-width: 400px) {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      column-gap: 0;

    }
  }

  &-item {
    width: 100%;

    @media (max-width: 400px) {
      width: 75%;
    }

    &-button {
      height: 94px !important;
      margin-bottom: 7px;
      border-radius: 24px;
      background-color: #3330E41A;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100% !important;
      flex-direction: column;
      box-shadow: 0px 2px 10px 0px #3330E480;
      transition: all 250ms linear;

      &:hover {
        transform: scale(1.03);
      }

      &-inner-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        cursor: pointer;
        gap: 6px;

        img {
          width: 32px;
          height: 32px;
        }

        p {
          text-align: center;
          font-family: Geometria;
          font-size: 14px;
          font-weight: 500;
          color: #3330E4;
        }

        margin-bottom: 14px;
      }


      span {
        font-family: Geometria;
        font-size: 12px;
        font-weight: 500;
        color: white;
        width: 100%;
        height: 24px;
        border-radius: 0 0 24px 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        align-content: center;
        padding-bottom: 3px;
      }
    }
  }

  &-text {
    color: var(--text);
    font-family: Geometria;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    background: rgba(51, 48, 228, 0.5);

    &-text {
      color: var(--text);
      font-family: Geometria;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: 75px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.account-client-past-promos-form-current {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  &-image {
    height: 100%;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-content {
    width: 55%;
    height: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;

    p {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      margin-top: 14px;
    }

    span {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
    }

    a {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
    }

    &-title {
      font-family: Geometria;
      font-size: 26px;
      font-weight: 700;
      text-align: left;
      color: blue;
    }
  }

  @media screen and (max-width: 1050px) {
    &-image {
      width: 35%;
      padding: 15px;
    }

    &-content {
      width: 65%;
      padding: 15px;
    }
  }

  @media screen and (max-width: 850px) {
    &-content {
      p {
        font-size: 16px;
      }

      span {
        font-size: 16px;
      }

      a {
        font-size: 16px;
      }

      &-title {
        font-size: 24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    &-image {
      margin-top: 20px;
      width: 100%;
    }

    &-content {
      width: 100%;
    }
  }
}

.account-client-past-promos-current-report {
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.account-client-mobile-filtering-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.account-client-mobile-filtering-buttons-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 27px;
  border-radius: 30px;
  padding: 10px;
  position: relative;
  background: #FF7A09;
  cursor: pointer;

  span {
    font-family: Geometria;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    margin: 0 auto;
  }

  img {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.account-client-save-and-estimate-form {
  .account-client-save-and-estimate-form-first-block {
    gap: 49px;
    margin-top: 30px;

    span {
      font-family: Geometria;
      font-size: 28px;
      font-weight: 700;
      color: #3330E4;
    }

    p {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 700;
      padding-top: 6px;
    }

    button {
      width: 74px;
      height: 35px;
      border-radius: 30px;
      font-family: Geometria;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .account-client-save-and-estimate-form-meetfox {
    width: 100%;
    height: 600px;
    border: none;
  }
}

.account-client-campaign-strategy {

  &-title {
    margin-top: 15px;

    p {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    @media screen and (max-width: 1020px) {
      margin-top: 0;
    }
  }

  &-details {
    padding: 0 69px;
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 60px auto;

    p {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 400;
    }

    span {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 700;
    }

    &-first,
    &-third {
      flex: 1;
      border: 2px solid #3330E4;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0 20px 64px;
    }

    &-second {
      flex: 1;
      border-top: 2px solid #3330E4;
      border-bottom: 2px solid #3330E4;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0 20px 64px;
    }

    &-first {
      border-radius: 40px 0 0 40px;
    }

    &-third {
      border-radius: 0 40px 40px 0;
    }

    @media screen and (max-width: 1150px) {
      padding: 0 30px;

      &-first, &-second, &-third {
        padding: 20px 0 20px 30px;
        font-size: 20px;
      }

      p {
        font-size: 22px;
      }

      span {
        font-size: 22px;
      }
    }

    @media screen and (max-width: 1000px) {
      &-first, &-second, &-third {
        padding: 20px 0 20px 20px;
      }
    }

    @media screen and (max-width: 850px) {
      padding: 0 20px;
      flex-direction: column;
      margin: 35px auto 0;

      p {
        font-size: 22px;
      }

      span {
        font-size: 22px;
      }

      &-first, &-second, &-third {
        padding: 20px 0 20px 40px;
      }

      &-first {
        border-radius: 40px 40px 0 0;
      }

      &-second {
        border-top: none;
        border-bottom: none;
        border-left: 2px solid #3330E4;
        border-right: 2px solid #3330E4;
      }

      &-third {
        border-radius: 0 0 40px 40px;
      }
    }

    @media screen and (max-width: 678px) {
      &-first, &-second, &-third {
        padding: 20px 0 20px 20px;
      }

      p {
        font-size: 18px;
      }

      span {
        font-size: 18px;
      }
    }
  }

  &-influencers {
    margin-top: 65px;

    .account-client-campaign-strategy-influencers-table-container {
      margin: 10px auto 0;
      box-shadow: 0px 4px 20px 0px #3330E480;
      border-radius: 30px;
      transition: width 0.3s ease;
    }

    .account-client-campaign-strategy-influencers-table-container.expanded {
      width: 90%;
    }

    .account-client-campaign-strategy-influencers-table {
      width: 100%;
      border-collapse: collapse;
    }

    .account-client-campaign-strategy-influencers-table-header {
      height: 84px;
      border-radius: 0 0 30px 30px;
      background: #3330E4;
      color: #fff;
      text-align: left;
    }

    .account-client-campaign-strategy-influencers-table-header th {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      padding: 20px;

      &:first-child {
        border-radius: 30px 0 0 0;
      }

      &:last-child {
        border-radius: 0 30px 0 0;
      }
    }

    .account-client-campaign-strategy-influencers-table td {
      padding: 9px 15px 9px 15px;
    }

    .account-client-campaign-strategy-influencers-table tbody tr td {
      border-bottom: 1px solid #c8c8f8;
    }

    .account-client-campaign-strategy-influencers-table tbody tr td:nth-child(1),
    .account-client-campaign-strategy-influencers-table tbody tr td:nth-child(3),
    .account-client-campaign-strategy-influencers-table tbody tr td:nth-child(6) {
      background: #ebebfd;
    }

    .account-client-campaign-strategy-influencers-table tbody tr td:nth-child(2),
    .account-client-campaign-strategy-influencers-table tbody tr td:nth-child(4),
    .account-client-campaign-strategy-influencers-table tbody tr td:nth-child(5) {
      background: #FFFFFF;
    }

    .account-client-campaign-strategy-influencers-table-footer {
      height: 84px;
      border-radius: 30px 30px 0 0;
      background: #3330E4;
      color: #fff;
      text-align: left;
    }

    .account-client-campaign-strategy-influencers-table-footer td {
      font-family: Geometria;
      font-size: 18px;
      font-weight: 800;
      background: #ffbd84;
      color: black;
    }

    .account-client-campaign-strategy-influencers-table-footer td:first-child {
      border-radius: 0 0 0 30px;
      background: #FF7A09;
      width: 250px;
    }

    .account-client-campaign-strategy-influencers-table-footer td:nth-child(2) {
      font-weight: 700;
    }

    .account-client-campaign-strategy-influencers-table-footer td:nth-child(3),
    .account-client-campaign-strategy-influencers-table-footer td:nth-child(3) {
      background: #ebad82;
      width: 200px;
    }

    @media screen and (max-width: 768px) {
      .account-client-campaign-strategy-influencers-table-container {
        display: none;
      }
      margin-top: 40px;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    button {
      width: 294px;
    }
  }

  &-mobile-table {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 30px auto;

    &-row {
      display: flex;
      justify-content: space-between;
      border-top: 2px solid #4f91ed;
      border-left: 2px solid #4f91ed;
      border-right: 2px solid #4f91ed;

      &:first-child {
        border-top: 2px solid #4f91ed;
        border-left: 2px solid #4f91ed;
        border-right: 2px solid #4f91ed;
        border-radius: 40px 40px 0 0;
      }

      &:last-child {
        border-bottom: none;
      }

      &-instagram {
        font-family: Geometria, sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        background: #c2c1f7;
        padding: 15px;
        flex: 1;
        display: flex;
        align-items: center;
      }

      &-content {
        padding: 10px;
        background: #fff;
        flex: 2;
        display: flex;
        flex-direction: column;


        &-followers {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          p {
            font-family: Geometria;
            font-size: 14px;
            font-weight: 400;
          }

          span {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
          }
        }

        &-genres {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-top: 10px;

          p {
            font-family: Geometria;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            white-space: nowrap;
          }

          span {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
            text-align: right;
          }

          @media (max-width: 768px) {
            p {
              white-space: pre-wrap;
            }
          }
        }

        &-countries {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-top: 10px;

          p {
            font-family: Geometria;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            white-space: nowrap;
          }

          span {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
            text-align: right;
          }

          @media (max-width: 768px) {
            p {
              white-space: pre-wrap;
            }
          }
        }


        &-date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-top: 10px;

          p {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
            text-align: right;
          }

          &-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
        }

        &-video {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-top: 10px;

          p {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
            text-align: right;
          }

          &-edit {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;

      &-price {
        padding: 20px;
        flex: 1;
        font-family: Geometria;
        font-size: 14px;
        font-weight: 800;
        border: 2px solid #4f91ed;
        border-radius: 0 0 0 40px;
        border-right: none;
        background: #FF7A09;
        display: flex;
        align-items: center;
      }

      &-followers {
        flex: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border: 2px solid #4f91ed;
        border-radius: 0 0 40px 0;
        border-left: none;
        background: #FFFFFF;
        padding: 0 20px 0 20px;

        p {
          margin-left: 10px;
          font-family: Geometria;
          font-size: 14px;
          font-weight: 700;
        }

        span {
          font-family: Geometria;
          font-size: 10px;
          font-weight: 400;
          margin-right: 10px;
        }
      }
    }

    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .account-client-back-button {
      display: none;
    }
  }

}

.container-form-details-question {
  width: 70%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    &-title {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-form {
      margin-top: -15px;

      p {
        margin-top: 15px;
      }
    }
  }
}

.container-form-save-estimate {
  width: 40%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 90%;
    &-title {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-form {
      margin-top: -15px;

      .account-client-save-and-estimate-form-first-block {
        margin-top: 70px;
      }
    }
  }
}

.container-post-campaign-form {
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 850px) {
    #account-client-post-delete-button {
      top: 0 !important;
      right: 0 !important;
      margin-top: 15px;
    }
  }
}

.account-client-post-campaign-content-item-block {
  margin: 75px auto 0;
  width: 50%;

  @media screen and (max-width: 850px) {
    width: 70%;
  }

  @media screen and (max-width: 500px) {
    width: 80%;
  }
}

@media screen and (max-width: 1800px) {
  .account-client-container-right-side {
    margin-left: 50px;
  }
  .account-client-container-right-side-upper-side-offers-search {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1650px) {
  /*Influencers List*/
  .account-client-choose-item-image {
    max-width: 60px;
    max-height: 60px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: grey 0px 0px 10px;
  }
  .account-client-choose-item-content-username {
    max-width: 120px;
    font-size: 18px;
  }
  .account-client-choose-item-content-text {
    max-width: 90px;
    font-size: 18px;
  }
  .account-client-choose-item-content-price {
    font-size: 18px;
  }
  .account-client-choose-item-content-third-container button {
    font-size: 16px;
  }
  .account-client-choose-item-back {
    span {
      font-size: 18px;
    }
  }
  .account-client-choose-item-back-right-side-genres {
    font-size: 14px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-name {
    font-size: 14px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-percentage {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  /*Influencers List*/
  .account-client-choose-item-image {
    max-width: 60px;
    max-height: 60px;
    margin-left: -40px;
  }
  .account-client-choose-item-content-username {
    max-width: 120px;
    font-size: 18px;
  }
  .account-client-choose-item-content-text {
    max-width: 90px;
    font-size: 18px;
  }
  .account-client-choose-item-content-price {
    font-size: 18px;
  }
  .account-client-choose-item-content-third-container button {
    font-size: 16px;
  }
  .account-client-choose-item-back {
    span {
      font-size: 18px;
    }
  }
  .account-client-choose-item-back-right-side-genres {
    font-size: 14px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-name {
    font-size: 14px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-percentage {
    font-size: 14px;
  }
}

@media screen and (max-width: 1300px) {
  .account-client-block {
    text-align: center;
    margin: 31px 0 0 31px !important;
  }
  .account-client-choose-item-image {
    max-width: 45px;
    max-height: 45px;
    margin-left: -10px;
  }
  .account-client-choose-item-content-username {
    font-size: 18px;
    margin-left: 10px;
  }
  .account-client-choose-item-content-text {
    margin-left: 6px;
    font-size: 18px;
  }
  .account-client-choose-item-content-third-container button {
    font-size: 16px;
  }
  .account-client-choose-item-content-second-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    width: 100%;
  }
  .account-client-choose-item-content-second-container-left-part {
    margin-left: 10px;
  }
  .account-client-choose-item-content-price {
    margin-right: 10px;
  }
  .account-client-choose-item-content-third-container .see-more-button {
    padding: 10px 0 0 0;
  }
  .account-client-choose-item-back {
    span {
      font-size: 20px;
    }
  }
  .account-client-choose-item-back-left-side {
    margin: 0;
  }
  .account-client-choose-item-back-right-side {
    margin: 0;
  }
  .account-client-choose-item-back-right-side-genres {
    font-size: 16px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-name {
    font-size: 16px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-percentage {
    font-size: 10px;
  }
  .account-client-choose-item-expanded-content .see-less-button {
    padding: 6px 0 0 0;
    margin: 0;
  }
}

@media (max-width: 1100px) {
  .account-client-choose-item-content-username {
    font-size: 20px;
    margin-left: 10px;
  }
  .account-client-choose-item-content-text {
    margin-left: 6px;
    font-size: 20px;
  }
  .account-client-choose-item-content-third-container button {
    font-size: 18px;
  }
  .account-client-choose-item-content-second-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    width: 100%;
  }
  .account-client-choose-item-content-second-container-left-part {
    margin-left: 10px;
  }
  .account-client-choose-item-content-price {
    margin-right: 10px;
  }
  .account-client-choose-item-content-third-container .see-more-button {
    padding: 10px 0 0 0;
  }
}

@media (max-width: 950px) {
  .account-client-block {
    text-align: center;
    margin: 31px 0 0 0 !important;
  }
  .account-client-choose-item-image {
    max-width: 30px;
    max-height: 30px;
    margin-left: 10px;
  }
  .account-client-choose-item-content-username {
    font-size: 16px;
    margin-left: 7px;
  }
  .account-client-choose-item-content-text {
    margin-left: 6px;
    font-size: 16px;
  }
  .account-client-choose-item-content-third-container button {
    font-size: 16px;
  }
  .account-client-choose-item-content-second-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .account-client-choose-item-content-second-container-left-part {
    margin-left: 10px;
    font-size: 16px;
  }
  .account-client-choose-item-content-price {
    margin-right: 10px;
    font-size: 16px;
  }
  .account-client-choose-item-content-third-container .see-more-button {
    padding: 14px 0 0 0;
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .account-client-block {
    text-align: center;
    margin: 31px 0 0 0 !important;
  }
  .account-client-choose-item {
    width: 500px;
    height: 185px;
  }
}

@media screen and (max-width: 768px) {
  .account-client-block {
    overflow: hidden;
  }
  .account-client-title-block {
    width: 100%;
    margin: 0 auto;
  }
  .account-client-title {
    font-family: Geometria;
    font-size: 28px;
    font-weight: 800;
    text-align: center;
  }

  .account-client-second {
    font-family: Geometria;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
  }

  .account-client-back-button {
    position: relative;
    z-index: 100000;
    top: -52px;
    left: 0;
    margin-left: -8%;
    max-width: calc(100% - 30px);
  }

  .account-client-back-button img {
    width: 30px;
    height: 30px;
  }

  //Influencers List
  .account-client-influencers-list-title {
    margin: -30px 0 0 0 !important;
  }
  .account-client-choose-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    gap: 21px;
    max-height: calc((3 * 115px + 2 * 21px) + 17px);
    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (min-height: 1100px) {
      max-height: calc(60vh - 150px);
    }
  }
  .account-client-choose-list::-webkit-scrollbar {
    width: 11px !important;
  }
  .account-client-choose-list::-webkit-scrollbar-track {
    background: #D6D6FA;
    border-radius: 10px;
    cursor: default;
  }
  .account-client-choose-list::-webkit-scrollbar-thumb {
    background: #3330E44D;
    border-radius: 10px !important;
  }
  .account-client-choose-item {
    min-width: 200px;
  }
  .account-client-choose-item-image {
    max-width: 30px;
    max-height: 30px;
    margin-left: 10px;
    padding-bottom: 1px;
  }
  .account-client-choose-item-content-username {
    font-size: 16px;
    margin-left: 7px;
  }
  .account-client-choose-item-content-text {
    margin-left: 6px;
  }
  .account-client-choose-item-content-third-container button {
    font-size: 16px;
  }
  .account-client-choose-item-content-second-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .account-client-choose-item-content-second-container-left-part {
    margin-left: 10px;
    font-size: 14px;
  }
  .account-client-choose-item-content-price {
    margin-right: 10px;
    font-size: 14px;
    align-items: center;

    span {
      font-size: 16px;
      margin-left: 5px;
    }
  }
  .account-client-choose-item-content-third-container .see-more-button {
    padding: 10px 0 0 0;
    font-size: 14px;
  }
  .account-client-choose-item-back {
    span {
      font-size: 16px;
    }
  }
  .account-client-choose-item-back-right-side-genres {
    font-size: 12px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-name {
    font-size: 12px;
  }
  .account-client-choose-item-back-left-side-country-percentage .country-percentage {
    font-size: 12px;
  }
  .account-client-choose-item-expanded-content .see-less-button {
    padding-top: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  //.account-client-block {
  //  margin: 15px 0 0 0 !important;
  //}

  .account-client-title-offers {
    margin: -30px 0 0 0 !important;
  }
}

.account-client-title-section {
  margin-top: 0;

  @media (max-width: 900px) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    margin-top: 40px;
  }
  @media (max-width: 400px) {
    margin-top: 0;
  }
}

.multicampaign-post-content-additional-modal {
  width: 100%;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }

  .select-content-type {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px 0px #3330E480;
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;

    .select-content-type-item {
      cursor: pointer;

      img {
        width: 45px;
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.9;
          transform: scale(1.1);
        }

        @media (max-width: 768px) {
          width: 30px;
        }
      }
    }
  }
}

.account-client-post-campaign-content {
  width: 50%;
  margin: 15px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease-in-out;

  @media screen and (max-width: 850px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 500px) {
    width: 80%;
  }
}

.new-vip-campaign-form-container {
  width: 90%;
  margin: 40px auto 0;

  #buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto 0;
    width: 45%;

    @media screen and (max-width: 1800px) {
      width: 60%;
    }
    @media screen and (max-width: 768px) {
      width: 40%;
    }
    @media screen and (max-width: 610px) {
      width: 55%;
    }
    @media screen and (max-width: 500px) {
      width: 75%;
    }
    @media screen and (max-width: 350px) {
      width: 95%;
    }
  }
}