.signup-client-block {
    padding-bottom: 80px;
}

.signup-client-modal {
    padding: 70px 0;
    @media screen and (max-width: 550px) {
        padding: 30px 20px;
    }
}

.signup-client-modal-icon {
    margin: 0 auto;
    margin-bottom: 30px;
}

.signup-client-modal-title {
    color: #3330e4;
    text-align: center;
    font-family: Geometria;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        font-size: 28px;
    }
    @media screen and (max-width: 550px) {
        font-size: 22px;
    }
}

.signup-client-modal-second {
    color: var(--text);

    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 512px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
    @media screen and (max-width: 550px) {
        font-size: 16px;
    }
}

.signup-client-modal-desc {
    color: var(--text);
    font-family: Geometria;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    max-width: 512px;
    margin: 0 auto;

    @media screen and (max-width: 550px) {
        font-size: 16px;
    }
}

.signup-client-agreement-block {
    padding-bottom: 60px;
}

.signup-client-agreement-second {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 53px;
}

.signup-client-agreement-form {
    max-width: 665px;
    margin: 0 auto;
}

.signup-client-agreement-form-flex {
    display: flex;
    gap: 17px;
}

.signup-client-agreement-form-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    &-text {
        color: var(--text);
        font-family: Geometria;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


.instagram-select {
    max-width: 665px;
    margin: 0 auto 30px auto;

    &-item {
        position: relative;

        &-file {
            color: transparent !important;
        }
        &-delete {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 10px;
            left: calc(100% + 15px);
            width: 35px;
            height: 35px;
            background-color: blue;
            border-radius: 50px;
            cursor: pointer;
            transition: background 250ms linear;

            &:hover {
                background-color: red;
            }

            &-icon {
                pointer-events: none;
                width: 20px;
                height: 20px;
            }
        }
    }
    
}