.header-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 45px 0 120px 0;
    width: 100%;
    
    @media screen and (max-width: 850px) {
        padding: 16px 0 30px 0;
    }
    @media (max-width: 400px) {
        flex-direction: column;
        padding: 16px 0 0;
    }
}

.header-navigation {
    display: flex;
    gap: 20px;
}

.header-thoomb {
    display: flex;
    align-items: center;
    gap: 35px;
    
    @media (max-width: 400px) {
        gap: 10px;
        margin: 0 auto;
        flex-direction: column;
    }
}

.header-path {
    color: var(--text);
    font-family: Geometria;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 850px) {
        position: absolute;
        top: 70px;
        bottom: 22px;
        left: 0;
        width: 100%;
        text-align: center;
    }
    
    @media (max-width: 400px) {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

.header-logo {
    cursor: pointer;
}

.header-theme {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    padding: 6px 10px;
    cursor: pointer;
    [data-theme="dark"] & {
        border: 1px solid rgba(51, 48, 228, 0.5);
    }
}

.header-login {
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    padding: 7px 19px;
    cursor: pointer;

    color: #ff7a09;
    text-align: center;
    font-family: Geometria;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.header-logout {
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    padding: 7px 19px;
    cursor: pointer;

    color: #ff7a09;
    text-align: center;
    font-family: Geometria;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 400px) {
        margin: 10px auto;
    }
}

.header-profile {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    padding: 6px 10px;
    cursor: pointer;
    [data-theme="dark"] & {
        border: 1px solid rgba(51, 48, 228, 0.5);
    }
}
