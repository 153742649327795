.block {
  position: relative;
  width: 99%;
  margin-top: 100px;
}

.label {
  position: relative;
}

.title {
  position: absolute;
  bottom: calc(100% + 23px);
  left: 0;
  color: var(--text);
  font-family: Geometria;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
  margin-left: 25px;
}

.error {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  
  color: #f00;
  font-family: Geometria;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 20%;
  border: 1px solid transparent;
  border-radius: 30px;
  background: var(--placeholder-bg);
  color: var(--text);
  font-family: Geometria;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: border 250ms linear;
}

.inputSilver {
  box-sizing: border-box;
  width: 100%;
  height: 20%;
  border: 1px solid transparent;
  border-radius: 30px;
  background: var(--placeholder-bg);
  padding: 13px 33px;
  color: var(--text);
  font-family: Geometria;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: border 250ms linear;
}

.inputSilver::placeholder {
  color: var(--placeholder);
  font-family: Geometria;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.inputSilver:focus {
  outline: none;
  border-radius: 30px;
  border: 1px solid #3330e4;
  background: var(--placeholder-bg);
}

.disabled {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 26px;
  transform: translateY(-50%);
}

.disabledText {
  color: var(--Blue, #3330e4);
  font-family: Geometria;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 16px;
  }

  .input {
    font-size: 16px;
  }

  .input::placeholder {
    font-size: 16px;
  }

  .inputSilver {
    font-size: 16px;
    height: 40px !important;
  }

  .inputSilver::placeholder {
    font-size: 16px;
  }
}
