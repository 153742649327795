.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 33px;
  border-radius: 30px;
  border: 1px solid #ff7a09;
  background: #fff;

  color: #ff7a09;
  text-align: center;
  font-family: Geometria;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition: color 200ms linear;
  transition-property: color, background;

  cursor: pointer;
}

@media (max-width: 768px) {
  .button {
    padding: 10px 20px;
    font-size: 20px;
  }
}

[data-theme="dark"] .button {
  background: #000;
}

.button:hover {
  color: #fff;
  background: #ff7a09;
}

[data-theme="dark"] .button:hover {
  color: #000;
}
