.search-container.small {
    width: 140px;
    height: 10px;
}

.search-container.small-medium {
    width: 200px;
    height: 10px;
}

.search-container.medium {
    width: 400px;
}

.search-container.large {
    width: 600px;
    height: 30px;
}
