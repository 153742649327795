.account-influencer-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &-score {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    &-span {
      font-weight: 400;
    }
  }
}

.account-influencer-details {
  padding-bottom: 80px;

  &-form {
    max-width: 550px;
    margin: 0 auto;
    padding-bottom: 50px;

    @media screen and (max-width: 768px) {
      padding: 0 20px;
      padding-bottom: 50px !important;
    }
  }
}

.account-influencer-details-second {
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 70px;
  
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.account-influencer-details-thoomb {
  display: flex;
  flex-direction: column;
  gap: 25px;
  
  @media (max-width: 900px) {
    width: 90%;
    margin: 0 auto;
  }
}

.account-influencer-details-wrapper {
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  border-radius: 30px;
  background: var(--background);

  .error-after-adding {
    color: red;
    font-family: Geometria;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }

  &-header {
    position: relative;
    border-radius: 30px 30px 0px 0px;
    background: #3330e4;
    padding: 25px 0;

    &-title {
      color: #fff;
      text-align: center;
      font-family: Geometria;
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    &-edit {
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &-content {
    max-width: 665px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-radius: 0px 0px 30px 30px;
    background: var(--background);
    padding: 40px 0;

    &-item {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
      }
    }

    &-title {
      width: 30%;
      color: var(--text);
      font-family: Geometria;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        width: 100%;
        text-align: center;
      }
    }

    &-value {
      width: 70%;
      color: var(--text);
      font-family: Geometria;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        width: 100%;
        text-align: center;
      }
    }
  }

  .select-social-media {
    margin-bottom: 60px;
    font-family: Geometria;
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    .social-medias-container {
      margin: 42px auto 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      @media (max-width: 600px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
      }
      
      li {
        width: calc((100% - (40px * 3)) / 3) !important;

        @media screen and (max-width: 850px) {
          width: calc((100% - (40px * 2)) / 2) !important;
        }

        @media screen and (max-width: 600px) {
          width: calc((100% - (80px * 1)) / 1) !important;
        }

        height: 84px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        box-shadow: 0px 4px 20px 0px #3330E480;
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
          margin-left: 30px;
          margin-bottom: 3px;
          
          @media screen and (max-width: 768px) {
            width: 35px;
            height: 35px;
          }
        }

        span {
          font-family: Geometria;
          font-size: 22px;
          font-weight: 700;
          text-align: center;
          margin-left: 14px;
          
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
      }

      li:hover {
        background-color: #F5F5F5;
        box-shadow: 0px 5px 23px 0px #3330E480;
      }

      .ready-account {
        cursor: pointer;
        background-color: #FF7A09BD;
        box-shadow: 0px 4px 20px 0px #FF7A09E5;
        width: calc((100% - (40px * 3)) / 3) !important;
        height: 84px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        position: relative;

        @media screen and (max-width: 850px) {
          width: calc((100% - (40px * 2)) / 2) !important;
        }
        @media (max-width: 600px) {
          width: calc((100% - (80px * 1)) / 1) !important;
        }
        
        li {
          width: 100% !important;
          height: 100%;
          background-color: transparent !important;
          box-shadow: none !important;
          position: relative;
        }

        .length {
          font-family: Geometria;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          background: #FB741E;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          padding: 5px;
          margin-left: 0;
          position: absolute;
          top: -8px;
          right: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

}

.account-influencer-details-approved-accounts-list {
  display: flex;
  flex-wrap: wrap;
  gap: 23px 100px;
  justify-content: center;

  .account-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(40% - 11.5px);
    box-sizing: border-box;

    p {
      font-family: Geometria;
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }

    #edit-account-img {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    gap: 23px 0;
    flex-direction: column;
    align-items: center;

    .account-item {
      width: 60%;
    }
  }

  .label-not-verified {
    display: flex;
    width: 119px;
    height: 22px;
    border-radius: 30px;
    background: #00000040;
    align-items: center;

    span {
      font-family: Geometria;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: white;
      width: 100%;
    }
  }
}


.delete-account-modal-window {
  padding: 62px 64px 62px 64px;

  .title-section {
    font-family: Geometria;
    font-size: 38px;
    font-weight: 800;

    p {
      color: black;
    }

    span {
      color: #FF7A09;
      margin-left: 12px;
    }
  }

  .warning-section {
    color: red;
    margin-top: 40px;

    p {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 800;
      text-align: left;
    }

    span {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 500;
      text-align: left;
    }
  }

  .inputs-section {
    padding: 0;
  }

  .buttons-section {
    display: flex;
    justify-content: center;
    gap: 13px;
    margin-top: 33px;
  }
}

.change-price-modal {
  padding: 80px 64px 0 64px;

  p {
    font-family: Geometria;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
  }
}

.account-influencer-new-approved-accounts {
  .title-block {
    margin: 0 auto;
    font-family: Geometria;
    font-size: 38px;
    font-weight: 800;
    text-align: center;

    span {
      color: blue;
    }

    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
  }

  .form-title {
    font-family: Geometria;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
  }

  .accounts-list-block {
    margin-top: 43px;

    .account-item {
      display: flex;
      justify-content: space-between;
      margin-top: 23px;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .account-item-username {
        margin-left: 15px;

        p {
          font-family: Geometria;
          font-size: 22px;
          font-weight: 700;
          text-align: center;
        }
      }

      .account-item-img {
        img {
          width: 39px;
          height: 39px;
        }
      }

      .account-item-price {
        white-space: nowrap;
        font-family: Geometria;
        font-size: 22px;
        font-weight: 500;
        text-align: left;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1200px) {
          text-align: center;
          white-space: none;
        }
      }
    }
  }

  .form-total-price {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    p {
      font-family: Geometria;
      font-size: 22px;
      font-weight: 500;
    }

    span {
      font-family: Geometria;
      font-size: 22px;
      font-weight: 800;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 45px;

    .form-button {
      display: flex;
      flex-direction: column;
      width: 210px;

      p {
        font-family: Geometria;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .form-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }
}

.influencer-price-input-container {
  margin: 62px auto 0;
  max-width: 665px;
  
  #price-input-title {
    font-family: Geometria;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }
  
  .price-input-container-block {
    display: flex;
    gap: 20px;
  }
}