.invoices-block-content-item-status-value{
    margin-right: 10px;
}
.invoices-second {
    color: #000;
    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.invoices-block {
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    margin-top: 46px;

    @media (max-width: 700px) {
        margin-top: 30px;
    }
    
    &-header {
        display: flex;
        align-items: center;
        padding: 25px 49px;
        border-radius: 30px 30px 0px 0px;
        background: #3330e4;

        @media screen and (max-width: 900px) {
            display: none;
        }

        &-title {
            flex: 2;
            color: #fff;
            font-family: Geometria;
            font-size: 26px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
    }

    &-content {
        padding-bottom: 32px;

        &-item {
            padding: 20px 49px;
            padding-right: 32px;
            border-bottom: 1px solid #3330e4;
            &-thoomb {
                display: flex;
                width: 100%;
            }

            &-mobile-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 5;

                @media screen and (max-width: 900px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &-mobile-block {
                display: flex;
                align-items: center;
                flex: 5.5;
                justify-content: flex-start;

                @media screen and (max-width: 900px) {
                    align-items: flex-end;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            &-id {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                flex: 1;
            }

            &-date {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                flex: 2;
            }

            &-bank {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                flex: 2;
            }

            &-value {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                flex: 1;
            }

            &-status {
                flex: 1;
                display: flex;
                justify-content: flex-start;

                &-value {
                    padding: 2px 17px;
                    border-radius: 30px;
                    background: #ff7a09;
                    color: #fff;
                    text-align: center;
                    font-family: Geometria;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            &-document {
                order: 1;
                margin-left: 5px;
            }

            &-message {
                flex: 3;
                display: flex;
                justify-content: flex-start;

                &-mobile {
                    display: none;
                }

                @media screen and (max-width: 900px) {
                    order: 1;
                    flex: unset;
                }

                @media screen and (max-width: 500px) {
                    display: none;

                    &-mobile {
                        display: flex;
                        margin-top: 15px;
                        text-align: center;
                    }
                }

                &-text {
                    padding: 4px 17px;
                    border-radius: 30px;
                    background: rgba(51, 48, 228, 0.3);
                    color: var(--text);
                    font-family: Geometria;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}
