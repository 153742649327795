.block {
    .title {
        position: relative;
        bottom: 9px;
        left: 0;
        color: var(--text);
        font-family: Geometria;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    .fileUploadBlock {
        display: flex;
        background: #EEF0F2;
        width: 100%;
        height: 48px;
        border-radius: 30px;
        align-items: center;

        input {
            display: none;
        }

        .uploadButton {
            background: #3330E4;
            width: 122px;
            height: 36px;
            border-radius: 30px;
            font-family: Geometria;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
            margin-left: 15px;
        }
        
        .placeholderAfterButton {
            font-family: Geometria;
            font-size: 18px;
            font-weight: 300;
            line-height: 22.64px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin-left: 15px;
        }
    }

    .error {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);

        color: #f00;
        font-family: Geometria;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
