.genre-button {
    width: 180px;
    height: 79px;
    padding: 10px;
    margin: 5px;
    border: 1px solid #3330E4;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Geometria;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    align-content: center;

    &:hover {
        box-shadow: 2px 2px 3px #3330E480;
    }
}

.genre-text {
    font-weight: bold;
}

.genre-subtext {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    align-items: center;
    line-height: 18.87px;
}

.genre-button.active {
    background: #3330E4;
    color: #ffffff;
    box-shadow: 2px 2px 3px #3330E480;
}

@media (max-width: 1300px) {
    .genre-button {
        min-width: 150px !important;
    }

}

@media screen and (max-width: 550px) {
    .genre-button {
        width: 106px;
        min-width: 106px;
        height: 45px;
        margin: 0 2px;
    }

    .genre-text {
        font-size: 15px;
        font-weight: 700;
    }

    .genre-subtext {
        font-size: 10px;
        font-weight: 700;
    }
}