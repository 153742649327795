.proposal-system-campaigns-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  margin: 60px auto 60px;

  .campaign {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1.5px solid blue;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 0px #3330E480;
    padding: 20px;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 600;

    .campaign-icon-name-block {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 40px;
      }

      p {
        font-family: Geometria;
        font-size: 20px;
        font-weight: 800;
      }
    }

    .campaign-info-item {
      display: flex;
      gap: 5px;
      flex-direction: column;

      span {
        font-family: Geometria;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .manage-buttons-block {
      display: flex;

      .delete-confirmation {
        display: flex;
        align-items: center;
        gap: 10px;
        opacity: 0;
        width: 0;
        height: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease, width 0.3s ease, height 0.3s ease;
        overflow: hidden;
        white-space: nowrap;

        #buttons {
          display: flex;
          gap: 10px;

          #accept {
            cursor: pointer;
            border: 2px solid black;
            border-radius: 10px;
            padding: 3px;

            img {
              width: 25px;
            }

            &:hover {
              border: 2px solid green;
              box-shadow: 0 0 7px 0 green;
              transition: box-shadow 0.3s ease, border 0.3s ease;
            }

            &:not(:hover) {
              transition: box-shadow 0.2s ease;
            }
          }
        }

        &.active {
          border: 2px solid black;
          border-radius: 10px;
          height: 40px;
          width: 225px;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }

      button {
        cursor: pointer;
        border: 2px solid black;
        border-radius: 10px;
        padding: 3px;

        img {
          width: 25px;
        }

        &:hover {
          box-shadow: 0 0 7px 0 black;
          transition: box-shadow 0.2s ease;
        }

        &:not(:hover) {
          transition: box-shadow 0.2s ease;
        }
      }
    }
  }
}

.create-new-campaign-button {
  cursor: pointer;
  width: 90%;
  margin: 40px auto 0;
  border: 1px solid blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
  border-radius: 40px;
  box-shadow: 0px 2px 10px 0px #3330E480;

  img {
    width: 20px;
  }

  p {
    font-family: Geometria;
    font-size: 15px;
    font-weight: 800;
    text-align: center;
  }

  &:hover {
    box-shadow: 0 0 10px 0 blue;
    transition: box-shadow 0.3s ease;
  }

  &:not(:hover) {
    transition: box-shadow 0.3s ease;
  }
}

.horizontal-line {
  width: 90%;
  height: 2.5px;
  background-color: black;
  margin: 30px auto;
  border-radius: 5px;
}

.proposal-system-navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.proposal-system-navigation {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-family: Geometria;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
}

.page {
  width: 80vw !important;
  position: relative;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.8s ease, transform 0.8s ease;
  z-index: 0;
}

.enter {
  opacity: 1;
  transform: scale(1);
  z-index: 1;
}

.exit-left {
  opacity: 0;
  transform: scale(0.9);
  z-index: -1;
}

.exit-right {
  opacity: 0;
  transform: scale(0.9);
  z-index: -1;
}

.proposal-system-clients-table-container {
  margin: 40px auto 40px;
}

.proposal-system-clients-table {
  margin: 0 auto;
  width: 80%;
  border-collapse: collapse;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  background: #fff;

  &-header {
    background: #3330E4;
    color: #fff;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    height: 66px;
  }

  &-body {
    tr {
      cursor: pointer;

      td {
        border-bottom: 1px solid #c8c4f4;
        border-right: 1px solid #c8c4f4;
        padding: 10px 0 10px 20px;
        width: 33.33%;

        font-family: Geometria;
        font-size: 16px;
        font-weight: 400;
        transition: background 0.3s ease, border 0.3s ease, transform 0.3s ease;
      }

      &:hover {
        background: #f5f5f5;
        box-shadow: 0 2px 10px 0 #3330E4;

        td {
          border: 1.5px solid #c8c4f4;
          font-weight: 500;
          transform: scale(1.05);
        }
      }

      &:not(:hover) {
        td {
          border: 1px solid #c8c4f4;
        }
      }

      &.selected-client {
        background: #f5f5f5;
        box-shadow: 0 2px 10px 0 #3330E4;

        td {
          border: 1.5px solid #c8c4f4;
          font-weight: 500;
          transform: scale(1.05);
        }
      }
    }
  }
}

.proposal-system-influencers {
  .details {
    margin: 0 auto;

    .details-campaign-name {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        font-family: Geometria;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        height: 40px;
        width: 100%;

        &::placeholder {
          font-family: Geometria;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          color: grey;
        }
      }

      @media (max-width: 634px) {
        width: 90%;
        margin: 0 auto;

        p {
          font-family: Geometria;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }
      }
    }

    .mobile-share-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 24px;

      img {
        cursor: pointer;
        width: 50px;
      }

      p {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
      }

      @media screen and (max-width: 634px) {
        p {
          font-size: 10px;
        }

        img {
          width: 40px;
        }
      }
    }
  }
}

.proposal-system-hide-offers-btn {
  cursor: pointer;
  text-decoration: underline;
  font-family: Geometria;
  font-size: 16px;
  font-weight: 400;
  margin: 40px auto 20px;
  display: flex;
}

.proposal-system-offers-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.7s ease, opacity 0.7s ease;

  &.visible {
    opacity: 1;
    max-height: 1500px;
  }
}

.proposal-system-influencers-table-container {
  margin: 40px auto 40px;
}

.proposal-system-influencers-table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  border-radius: 30px;
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  background: #fff;

  &-header {
    background: #3330E4;
    color: #fff;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    height: 66px;

    tr {
      th:first-child {
        border-radius: 30px 0 0 0;
      }

      th:last-child {
        border-radius: 0 30px 0 0;
      }
    }

    .th-with-text-above {
      position: relative;
    }

    #th-header {
      position: absolute;
      top: -40px;
      left: 0;
      background-color: #9894ec;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 30px 30px 0 0;
      font-family: Geometria;
      font-size: 14px;
      font-weight: 900;
      cursor: pointer;
      transition: background 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        background-color: #827eea;
        box-shadow: 0 -2px 5px 0 #827eea;
      }
    }

    #th-header img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    #th-header span {
      margin-bottom: 1px;
    }
  }

  &-body {
    td {
      border-top: 1px solid blue;
    }

    td:first-child {
      background-color: #c8c4f4;
      font-family: Geometria;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
    }

    td:nth-child(2n+3) {
      background-color: #f0ecfc;
    }

    td:nth-child(2n+2):not(:first-child) {
      background-color: #ffffff;
    }

    .logo-username {
      display: flex;
      align-items: center;
      padding: 7px 0 7px 12px;
      height: 40px;
      min-width: 200px;

      #logo {
        cursor: pointer;
        border-radius: 50%;

        img {
          width: 50px;
        }

        &:hover {
          box-shadow: 0 0 7px 0 black;
          transition: box-shadow 0.2s ease;
        }

        &:not(:hover) {
          transition: box-shadow 0.2s ease;
        }
      }

      #image {
        cursor: pointer;
        border: 2px solid black;
        border-radius: 10px;
        padding: 5px;

        img {
          width: 13px;
        }

        &:hover {
          box-shadow: 0 0 7px 0 black;
          transition: box-shadow 0.2s ease;
        }

        &:not(:hover) {
          transition: box-shadow 0.2s ease;
        }
      }

      p {
        margin-left: 8px;
      }
    }

    .followers-number {
      font-family: Geometria;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      width: 5% !important;
    }

    .account-info {
      width: 7% !important;

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          cursor: pointer;
          border: 2px solid black;
          border-radius: 10px;
          padding: 3px;

          img {
            width: 25px;
          }

          &:hover {
            box-shadow: 0 0 7px 0 black;
            transition: box-shadow 0.2s ease;
          }

          &:not(:hover) {
            transition: box-shadow 0.2s ease;
          }
        }
      }
    }

    .date {
      min-width: 100px;

      #first {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      input {
        font-family: Geometria;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        max-width: 80px;
      }
    }

    .select-content {
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            cursor: pointer;
            border: 2px solid black;
            border-radius: 10px;
            padding: 3px;

            img {
              width: 25px;
            }

            &:hover {
              box-shadow: 0 0 7px 0 black;
              transition: box-shadow 0.2s ease;
            }

            &:not(:hover) {
              transition: box-shadow 0.2s ease;
            }
          }
        }
      }
    }

    .post-description {
      padding: 2px 0 2px 5px;

      div {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        text-indent: 10px;
        max-height: 50px !important;
        overflow-y: auto !important;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D6D6FA;
          border-radius: 4px;
          transition: background 0.3s ease;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: rgba(50, 47, 228, 0.7);
        }

        &::-webkit-scrollbar-track {
          background: #EBEAFC;
          border-radius: 4px;
        }
      }
    }

    .story-tag {
      div {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        padding-left: 5px;
        padding-right: 5px;
        text-align: left;
        max-height: 50px !important;
        overflow-y: auto !important;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D6D6FA;
          border-radius: 4px;
          transition: background 0.3s ease;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: rgba(50, 47, 228, 0.7);
        }

        &::-webkit-scrollbar-track {
          background: #EBEAFC;
          border-radius: 4px;
        }
      }
    }

    .story-link {
      font-family: Geometria;
      font-size: 16px;
      font-weight: 400;
      padding: 0 5px;
      text-align: left;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: blue;
    }

    .special-requests {
      padding: 2px 0 2px 5px;
      max-width: 300px;
      
      div {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        text-indent: 10px;
        max-height: 50px !important;
        overflow-y: auto !important;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D6D6FA;
          border-radius: 4px;
          transition: background 0.3s ease;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: rgba(50, 47, 228, 0.7);
        }

        &::-webkit-scrollbar-track {
          background: #EBEAFC;
          border-radius: 4px;
        }
      }
    }

    .add-new-account-tr {
      td:first-child {
        padding-left: 12px;
        background: #b0e4cc;
        display: flex;
        align-items: center;
        height: 54px;
        gap: 8px;
        cursor: pointer;

        .add-new-account-tr-plus {
          border: 2px solid black;
          border-radius: 10px;
          padding: 3px;

          img {
            width: 18px;
          }
        }

        p {
          font-family: Geometria;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
        }

        &:hover {
          box-shadow: 0 0 7px 0 black;
          transition: box-shadow 0.2s ease;
        }

        &:not(:hover) {
          transition: box-shadow 0.2s ease;
        }
      }
    }
  }

  &-footer {
    border-top: 1px solid #c28f80;

    td {
      height: 72px;
      font-family: Geometria;
      font-size: 16px;
      font-weight: 700;
      text-align: center;

      &:first-child {
        border-radius: 0 0 0 30px;
      }

      &:last-child {
        border-radius: 0 0 30px 0;
      }
    }

    td:first-child {
      background: #ff7c0c;
      font-family: Geometria;
      font-size: 18px;
      font-weight: 800;
      text-align: center;
      //padding-left: 43px;
    }

    td:nth-child(2n+3) {
      background-color: #ffbc84;
    }

    td:nth-child(2n+2):not(:first-child) {
      background-color: #f0ac84;
    }
  }
}

.proposal-system-influencers-account-info-modal {
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .block {
    .title {
      font-family: Geometria;
      font-size: 30px;
      font-weight: 800;
      text-align: center;
      color: #3330e4;
    }

    .content {
      margin-top: 15px;
      font-family: Geometria;
      font-size: 22px;
      font-weight: 700;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 0;

      .genres-container {
        margin: 0 auto;
        width: 60%;
      }

      .countries-container {
        margin: 0 auto;
        width: 60%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.select-campaign-content-item-modal {
  padding: 50px 60px 40px;

  .title {
    font-family: Geometria;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    color: black;
  }

  .select-container {
    margin: 30px auto 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .campaign-content-item {
      width: calc(20% - 10px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 7px;
      cursor: pointer;
      border: 2px solid black;
      border-radius: 10px;

      img {
        width: 25px;
      }

      span {
        font-weight: 700;
        font-size: 20px;
        font-family: Geometria;
        margin-bottom: 2px;
      }

      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 4px 20px black;
      }

      &:not(:hover) {
        box-shadow: none;
      }

      &.chosen {
        box-shadow: 0 2px 10px blue;
        border: 2px solid blue;
      }
    }
  }

  .chosen-content-item-container {
    margin-top: 30px;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transform: translateY(-20px);
    transition: max-height 1s ease, opacity 1s ease, transform 0.5s ease;

    &.visible {
      max-height: 2000px;
      opacity: 1;
      transform: translateY(0);
    }

    .chosen-content-item {
      display: flex;
      flex-direction: column;
      padding: 8px 10px;
      border: 2px solid black;
      box-shadow: 0 2px 10px 0 black;
      border-radius: 10px;

      .chosen-content-item-details {
        p {
          font-family: Geometria;
          font-size: 20px;
          font-weight: 700;

          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        a {
          font-family: Geometria;
          font-size: 20px;
          font-weight: 400;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word;
          white-space: normal;
          text-indent: 20px;
        }

        span {
          font-family: Geometria;
          font-size: 20px;
          font-weight: 400;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word;
          white-space: normal;
          text-indent: 20px;
        }
      }
    }

    .select-button {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.content-form-container {
  margin-top: 40px;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.fade-in {
    transform: translateY(0);
  }

  .title {
    font-family: Geometria;
    font-size: 24px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .content-form {
    position: relative;

    .remove-button {
      cursor: pointer;
      position: absolute;
      top: -70px;
      right: 20px;
      z-index: 1;

      img {
        width: 35px;
        transition: all 0.3s ease;
      }

      img:hover {
        transform: scale(1.1);
      }

      @media (max-width: 768px) {
        img {
          width: 25px;
        }

        top: -45px;
        right: 18px;
      }
    }

    .content-form-item {
      margin: 60px auto 0;
      width: 80%;

      @media (max-width: 768px) {
        margin: 40px auto 0;
      }
    }
  }

  .add-additional-content-button {
    margin: 40px auto 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.proposal-system-influencers-buttons {
  margin: 40px auto 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.proposal-system-title-with-btn {
  display: flex;
  height: 65px;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  #back-btn {
    cursor: pointer;

    img {
      width: 40px;
    }
  }

  #title {
    margin-top: 5px;
  }

  #share {
    cursor: pointer;

    img {
      width: 40px;
    }
  }

  @media (max-width: 800px) {
    width: 90%;

    #back-btn {
      display: none;
    }

    #share {
      display: none;
    }

    #title {
      margin: 0 auto;
      width: 100%;
    }
  }

  @media (max-width: 634px) {
    margin: 23px auto 0;
  }

  @media (max-width: 400px) {
    margin: 40px auto 0;
  }
}

.proposal-system-shared-campaign-table-container {
  margin: 0 auto 40px;
  width: 90% !important;
  transition: width 0.2s ease;

  &.wider {
    margin: 0 40px 40px 40px;
    overflow-x: auto;
    width: 101% !important;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #D6D6FA;
      border-radius: 4px;
      transition: background 0.3s ease;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: rgba(50, 47, 228, 0.7);
    }

    &::-webkit-scrollbar-track {
      background: #EBEAFC;
      border-radius: 4px;
    }
  }

  @media (max-width: 1150px) {
    width: 80% !important;
  }
}

.proposal-system-shared-campaign-table-body {
  td {
    background-color: white;
    border: 1px solid #c8c4f4;
  }

  #username {
    min-width: 206px;
    padding: 9px 0 9px 17px;
    font-family: Geometria;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    background-color: #f0ecfc;

    img {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 1650px) {
      width: 150px !important;
      min-width: 150px !important;
    }
  }

  #followers {
    width: 108px;
    font-family: Geometria;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    background-color: #f0ecfc;
  }

  #date {
    max-width: 200px !important;
    font-family: Geometria;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding: 9px 0 9px 20px;

    @media (max-width: 1650px) {
      width: 100px !important;
      padding-left: 10px;
    }
  }

  #videolink {
    padding: 5px 3px;
    border: none;
    border-bottom: 1px solid #c8c4f4;

    div {
      margin: 0 auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 45px;
      height: 28px;
      border-radius: 10px;
      border: 1px solid black;
      padding: 0 8px;
      transition: box-shadow 0.3s ease;

      p {
        font-family: Geometria;
        font-size: 15px;
        font-weight: 500;
        margin-left: 5px;
        margin-bottom: 1px;
      }

      img {
        width: 18px;
      }

      &:hover {
        box-shadow: 0 0 7px 0 black;
      }
    }
  }

  #post-decription {
    min-width: 300px;
    width: 23%;
    padding: 4px 0 4px 17px;

    div {
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      text-indent: 10px;
      max-height: 40px !important;
      overflow-y: auto !important;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #D6D6FA;
        border-radius: 4px;
        transition: background 0.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(50, 47, 228, 0.7);
      }

      &::-webkit-scrollbar-track {
        background: #EBEAFC;
        border-radius: 4px;
      }
    }

    @media (max-width: 1650px) {
      width: 250px !important;
      min-width: 250px !important;
    }

    @media (max-width: 1400px) {
      width: 150px !important;
      min-width: 150px !important;
    }
  }

  #story-tag {
    width: 150px;

    div {
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      padding-left: 5px;
      padding-right: 5px;
      text-align: left;
      max-height: 50px !important;
      overflow-y: auto !important;

      &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #D6D6FA;
        border-radius: 4px;
        transition: background 0.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(50, 47, 228, 0.7);
      }

      &::-webkit-scrollbar-track {
        background: #EBEAFC;
        border-radius: 4px;
      }
    }
  }

  #story-link {
    cursor: pointer;
    max-width: 150px !important;
    font-family: Geometria;
    font-size: 15px;
    font-weight: 400;
    padding: 0 5px 2px 5px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: blue;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #D6D6FA;
      border-radius: 4px;
      transition: background 0.3s ease;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: rgba(50, 47, 228, 0.7);
    }

    &::-webkit-scrollbar-track {
      background: #EBEAFC;
      border-radius: 4px;
    }

    @media (max-width: 1650px) {
      width: 100px !important;
      max-width: 100px !important;
    }
  }

  #special-requests {
    padding: 4px 0 4px 17px;
    max-width: 300px !important;

    div {
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      text-indent: 10px;
      max-height: 40px !important;
      overflow-y: auto !important;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #D6D6FA;
        border-radius: 4px;
        transition: background 0.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(50, 47, 228, 0.7);
      }

      &::-webkit-scrollbar-track {
        background: #EBEAFC;
        border-radius: 4px;
      }
    }

    @media (max-width: 1650px) {
      max-width: 200px !important;
    }

    @media (max-width: 1450px) {
      max-width: 150px !important;
      text-indent: 5px;
      padding: 4px 0 4px 10px;
    }

    @media (max-width: 1400px) {
      max-width: 100px !important;
    }
  }

  #genres {
    padding: 4px 0 4px 17px;
    min-width: 250px;

    div {
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      text-indent: 10px;
      max-height: 40px !important;
      overflow-y: auto !important;
      overflow-x: hidden;

      p {
        font-family: Geometria;
        font-size: 15px;
        font-weight: 400;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #D6D6FA;
        border-radius: 4px;
        transition: background 0.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(50, 47, 228, 0.7);
      }

      &::-webkit-scrollbar-track {
        background: #EBEAFC;
        border-radius: 4px;
      }
    }
  }

  #countries {
    min-width: 500px;
    overflow-x: hidden;
    height: 40px;

    div {
      padding-left: 10px;
      font-family: Geometria;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      text-indent: 10px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #D6D6FA;
        border-radius: 4px;
        transition: background 0.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(50, 47, 228, 0.7);
      }

      &::-webkit-scrollbar-track {
        background: #EBEAFC;
        border-radius: 4px;
      }

      #country {
        font-family: Geometria;
        font-size: 15px;
        font-weight: 400;
      }

      #percentage {
        font-family: Geometria;
        font-size: 11px;
        font-weight: 400;
      }
    }
  }

  &.wider {
    #date {
      width: 150px !important;
      padding: 9px 0 9px 20px;
    }

    #videolink {
      width: 120px;
      padding-left: 5px;
      padding-right: 5px;
    }

    #story-link {
      width: 150px !important;
      max-width: 150px !important;
    }

    #post-decription {
      min-width: 350px !important;
      white-space: wrap;
      wrap-option: wrap;
    }

    #special-requests {
      min-width: 350px !important;
      white-space: wrap;
      wrap-option: wrap;
    }
  }
}

.proposal-system-shared-campaign-buttons {
  margin: 40px auto 40px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
  }
}

.hide-genres-countries-buttons {
  margin-right: 22%;
  margin-bottom: 20px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  width: auto;
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.hidden {
    height: 0;
    opacity: 0;
    transform: translateY(30px);
  }

  .btn {
    box-shadow: 0px 4px 20px 0px #3330E480;
    cursor: pointer;
    background: white;
    border-radius: 30px;
    width: auto;
    height: auto;
    align-items: center;
    display: flex;
    font-family: Geometria;
    font-size: 14px;
    font-weight: 800;
    text-align: left;
    padding: 12px 14px;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease, background-color 0.3s ease;

    &.hidden {
      opacity: 0;
      transform: translateY(20px);
      pointer-events: none;
    }

    p {
      margin-left: 9px;
      margin-bottom: 1px;
    }

    &:hover {
      box-shadow: 0 0 7px 0 blue;
      background: whitesmoke;
      transition: box-shadow 0.3s ease, background-color 0.3s ease;
    }
  }
}

.proposal-system-shared-campaign-mobile-table-container- {
  width: 90%;
  margin: 60px auto 66px;
  box-shadow: 0px 4px 20px 0px #3330E480;
  border-radius: 30px;
}

.proposal-system-shared-campaign-mobile-table {
  .account-item {
    .header {
      font-family: Geometria;
      font-size: 14px;
      font-weight: 700;
      background: #3330E4;
      color: white;
      height: 49px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .body {
      display: flex;

      .account-info {
        display: flex;
        flex-direction: column;
        background-color: #c8c4f4;
        //padding: 13px 5px 9px 11px;
        gap: 10px;
        width: 35%;
        max-width: 35%;

        p {
          margin-left: 11px;
          font-family: Geometria;
          font-size: 10px;
          font-weight: 400;
          color: #808080;
        }

        span {
          margin-left: 11px;
          font-family: Geometria;
          font-size: 14px;
          font-weight: 400;
        }

        #genres {
          span {
            display: block;
          }
        }

        .footer-left-side {
          background: #ff7c0c;
          border-radius: 0 0 0 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 72px;
          margin-top: auto;
          
          p {
            margin: 0;
            text-align: center;
            color: black;
            max-width: 46px;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      .campaign-info {
        min-width: 65% !important;
        background-color: white;
        gap: 10px;
        display: flex;
        flex-direction: column;

        p {
          margin-left: 19px;
          font-family: Geometria;
          font-size: 10px;
          font-weight: 400;
          color: #808080;
        }

        span {
          margin-left: 19px;
          font-family: Geometria;
          font-size: 15px;
          font-weight: 400;
        }

        #videoLink {
          #img {
            margin-left: 19px;
            cursor: pointer;
            margin-top: 3px;
            border: 1.5px solid #000000;
            width: 49px;
            height: 23px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            padding: 0 3px;

            span {
              margin: 0;
              font-weight: 500;
            }
          }

          #img:hover {
            box-shadow: 0 0 7px 0 black;
            transition: box-shadow 0.2s ease;
          }
        }

        #post-description {
          div {
            padding-left: 19px;
            max-height: 58px;
            overflow-y: hidden;
            overflow-x: hidden;
            transition: max-height 0.5s ease;
            
            span {
              margin: 0;
            }
          }
          
          button {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
            color: grey;
            text-decoration: underline;
            text-decoration-style: solid;
            text-underline-position: from-font;
            text-align: center;
            cursor: pointer;
            width: 100%;
          }
        }

        #story-link {
          div {
            margin-left: 19px;
            width: 90%;
            overflow-x: auto;
            overflow-y: hidden;

            span {
              margin-left: 0;
              color: blue;
              text-decoration: underline;
              text-decoration-style: solid;
              text-underline-position: from-font;
            }

            &::-webkit-scrollbar {
              height: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: #D6D6FA;
              border-radius: 4px;
              transition: background 0.3s ease;
            }

            &:hover::-webkit-scrollbar-thumb {
              background: rgba(50, 47, 228, 0.7);
            }

            &::-webkit-scrollbar-track {
              background: #EBEAFC;
              border-radius: 4px;
            }
          }
        }

        .footer-right-side {
          background: #ffbc84;
          border-radius: 0 0 30px 0;
          height: 72px;
          align-items: center;
          display: flex;
          margin-top: auto;
          
          p {
            font-size: 14px;
            font-weight: 700;
            color: black;
            max-width: 46px;
            margin: 0 0 0 19px;
          }
        }
      }
    }
  }
}
