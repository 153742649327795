.title {
  cursor: default;
  color: #000;
  text-align: center;
  font-family: Geometria;
  font-size: 38px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 9px;
}

[data-theme="dark"] .title {
  color: #fff;
}

.span {
  cursor: default;
  color: #000;
  text-align: center;
  font-family: Geometria;
  font-size: 38px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  color: #3330e4;
}

@media screen and (max-width: 600px) {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .title {
    font-size: 28px;
    display: inline;
    white-space: nowrap;
  }

  .span {
    font-size: 28px;
    display: block;
  }
}

