.custom-select-container {
    width: 100%;
    position: relative;
    height: 100%;
    font-family: Geometria;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.custom-select-header {
    padding: 5px 12px;
    border-radius: 10px;
    border: 2px solid black;
    background-color: #fff;
    cursor: pointer;
    user-select: none;
    transition: border-radius 0.3s ease;
}

.custom-select-header.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 2px solid black;
    border-radius: 10px;    
    z-index: 11111;
    box-shadow: 0 4px 20px black;
    opacity: 0;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
}

.custom-select-dropdown.open {
    background: white;
    opacity: 1;
    transform: translateY(8px);
    pointer-events: auto;
    z-index: 1000;
}

.custom-select-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 8px;
}

.custom-select-item:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
}

.custom-select-item.selected {
    background-color: #c8c4f4;
}

@media (max-width: 768px) {
    .custom-select-container {
        font-size: 14px;
        font-weight: 500;
    }

    .custom-select-header {
        padding: 5px 10px;
        border: 1px solid black;
    }

    .custom-select-dropdown {
        border: 1px solid black;
    }
}
