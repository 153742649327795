.offers-list-container {
    padding: 0;
    width: 100%;
}

.genres-list {
    
}

.offers-list {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    box-sizing: border-box;

    .control-button {
        display: flex;
        align-items: center;

        img {
            cursor: pointer;
        }
    }
}

.offer-item {
    cursor: pointer;
    width: 303px;
    background: white;
    height: 682px;
    border-radius: 30px;
    box-shadow: 0px 4px 20px 0px #3330E480;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: color 0.5s ease, background 0.5s ease, box-shadow 0.5s ease;
    
    .offer-item-title {
        margin-top: 37px;
        font-family: Geometria;
        font-size: 26px;
        font-weight: 900;
        text-align: center;
        color: blue;
    }

    .offer-item-description {
        margin-top: 21px;
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .offer-item-influencers-list {
        padding-left: 15px;
        overflow-y: auto;
        height: 470px;
        margin: 10px 0;

        .influencer-item {
            display: flex;
            align-items: center;

            img {
                width: 40px;
                border-radius: 50%;
            }

            p {
                font-family: Geometria;
                font-size: 16px;
                font-weight: 500;
                text-align: left;
                margin-left: 10px;
            }
        }

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D6D6FA;
            border-radius: 10px;
            transition: background-color 0.5s ease, border-radius 0.5s ease;
        }

        &::-webkit-scrollbar-track {
            background-color: #EBEAFC;
            border-radius: 10px;
            transition: background-color 0.5s ease, border-radius 0.5s ease;
        }
    }

    .offer-item-price {
        height: 84px;
        border-radius: 0px 0px 30px 30px;
        background: #3330e4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Geometria;
        font-size: 26px;
        font-weight: 900;
        color: white;
    }
    
    &:hover {
        background: #f8943c;
        box-shadow: 0px 4px 20px 0px #FF7A09E5;
        color: black;
        
        .offer-item-title {
            color: black;
        }
        
        .offer-item-price {
            background: #ff7c0c;
            color: black;
        }
        
        .offer-item-description {
            color: black;
        }
        
        .offer-item-influencers-list {
            .influencer-item {
                p {
                    color: black;
                }
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #FF7A09E5;
            }
            
            &::-webkit-scrollbar-track {
                background-color: #f8943c;
            }
        }
    }
    
    &.selected {
        background: #f8943c;
        box-shadow: 0px 4px 20px 0px #FF7A09E5;
        color: black;
        
        .offer-item-title {
            color: black;
        }
        
        .offer-item-price {
            background: #ff7c0c;
            color: black;
        }
        
        .offer-item-description {
            color: black;
        }
        
        .offer-item-influencers-list {
            .influencer-item {
                p {
                    color: black;
                }
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #FF7A09E5;
            }
            
            &::-webkit-scrollbar-track {
                background-color: #f8943c;
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 8px;
}

.pagination .dot {
    width: 10px;
    height: 10px;
    background-color: #d6d6fa;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination .dot.active {
    background-color: #3330e4;
}
