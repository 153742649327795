.admin-title-section {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  #first-button {
    position: absolute;
    left: 77%;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #000000;
    width: 89px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 4px 1px 4px;

    img {
      width: 17px;
    }
  }

  #second-button {
    position: absolute;
    left: 83%;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #000000;
    width: 126px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 4px 1px 4px;

    img {
      width: 20px;
    }
  }

  #third-button {
    position: absolute;
    left: 84.5%;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #000000;
    width: 89px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 4px 1px 4px;

    img {
      width: 17px;
    }
  }
}

.admin-title-section button {
  position: absolute;
  left: 10%;
  width: 65px;
  padding-bottom: 8px;
  cursor: pointer;
}

.admin-home-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 60px;
}

.admin-home-menu-item {
  width: calc((100% - 40px) / 3);

  @media screen and (max-width: 900px) {
    width: calc((100% - 20px) / 2);
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.admin-home-menu-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 48px 0;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  cursor: pointer;
  transition: background 250ms linear;
  transition-property: background, box-shadow;

  &:hover {
    background: rgba(51, 48, 228, 0.1);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.7);
  }
}

.admin-home-menu-button-text {
  color: var(--text);
  text-align: center;
  font-family: Geometria;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.admin-clients-searchbar {
  width: 40%;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.admin-table-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-table {
  width: 95%;
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  background: #fff;

  &-header {
    background: #3330E4;
    color: #fff;
    font-family: Geometria;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    height: 66px;
  }

  .admin-table-body-td {
    border-bottom: 1px solid #c8c4f4;
    border-right: 1px solid #c8c4f4;
    padding-left: 10px;

    font-family: Geometria;
    font-size: 16px;
    font-weight: 400;
  }
}

.admin-influencers-platforms {
  display: grid;
  place-items: center;
  margin-top: 26px;
  gap: 25px;
}

.admin-influencers-platforms-container {
  display: flex;
  gap: 20px;
}

.admin-influencers-platforms-container-item {
  width: 200px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  background: var(--background);
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
  transition: background 250ms linear;
  transition-property: background, box-shadow;

  button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: var(--text);
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    padding-left: 15px;
    gap: 10px;
    cursor: pointer;
  }

  &.active {
    background: #f08c44;
    box-shadow: 0px 4px 20px 0px #f08c44;
  }

  &.future {
    background: grey;
  }
}

.admin-influencers-table-container {
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 40px;
  overflow-x: auto;
  border-radius: 30px;

  margin-right: 30px;
}

.admin-influencers-table {
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 30px;
  overflow: hidden;
}

.admin-influencers-table-header {
  font-family: Geometria;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
}

.admin-influencers-table-header-th {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.admin-influencers-table-header-button {
  background: #6A5ACD;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px 50px 0 0;
}

#first {
  border-radius: 50px 0 0 0;
}

#second {
  border-radius: 0 50px 0 0;
}

.admin-influencers-table-header-text {
  background: #3330e4;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.admin-influencers-table-header-button img {
  width: 20px;
}

.admin-influencers-table-body-td {
  border-bottom: 1px solid #c8c4f4;
  border-right: 1px solid #c8c4f4;
  border-left: 1px solid #c8c4f4;
  padding-left: 10px;
  background: #FFFFFF;
}

.hidden-columns-list {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  align-items: center;
  justify-content: center;


  .hidden-column-item {
    display: flex;
    font-family: Geometria;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    gap: 7px;
  }
}

.admin-offers-add-offer {
  margin: 44px auto 20px auto;
  width: fit-content;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: 3px solid #000000;
    border-radius: 10px;
    font-family: Geometria;
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    padding: 2px 7px 2px 3px;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    img {
      margin-bottom: 1px;
    }
  }
}

.admin-offers-list {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .admin-offers-list-left-button {
    cursor: pointer;
  }

  .admin-offers-list-right-button {
    cursor: pointer;
  }
}

.admin-offers-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #b1b0f5;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .dot.active {
    background-color: blue;
  }
}

.admin-offers-deleted-offers {
  margin-bottom: 40px;

  #open-deleted-offers {
    cursor: pointer;
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #000000;
    width: 220px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto 30px auto;
    padding: 0 10px 0 10px;
  }

  .admin-deleted-offers {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

.admin-container-offer {
  .admin-delete-offer-modal {
    #buttons {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    h3 {
      font-family: Geometria;
      font-size: 23px;
      font-weight: 900;
      text-align: center;
      margin-top: 55px;
      margin-bottom: 21px;
    }
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .admin-container-offer-buttons {
    display: flex;
    gap: 8px;
    margin: 0 auto 26px auto;
    width: fit-content;

    button {
      border: 2px solid #000000;
      width: 28px;
      height: 28px;
      border-radius: 10px;
      cursor: pointer;

      img {
        margin: 0 auto;
      }
    }
  }

  .admin-offer {
    box-shadow: 0px 4px 20px 0px #3330E480;
    border-radius: 30px;
    overflow: hidden;
    width: 303px;
    height: 682px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .admin-offer-body {
      display: flex;
      flex-direction: column;
      background: white;
      height: 598px;

      h3 {
        font-family: Geometria;
        font-size: 26px;
        font-weight: 900;
        text-align: center;
        color: #3330e4;
        margin-top: 37px;
        margin-bottom: 21px;
      }

      p {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }

      .admin-offer-body-influencers {
        margin-top: 25px;
        overflow-y: auto !important;
        max-height: 400px;

        .admin-offer-body-influencer {
          display: flex;
          align-items: center;

          p {
            font-family: Geometria;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.13px;
            text-align: left;
          }

          img {
            width: 38px;
            margin-right: 10px;
            margin-left: 13px;
          }
        }
      }
    }

    .admin-offer-footer {
      background: #3330e4;
      color: white;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-family: Geometria;
        font-size: 26px;
        font-weight: 900;
        text-align: center;
      }
    }
  }
}

.admin-offers-publish-modal {
  height: 100px;
  width: 100%;
  padding-top: 50px;
  font-family: Geometria;
  font-size: 26px;
  font-weight: 900;
  text-align: center;
  color: #3330e4;
}

.admin-offer-body-influencers::-webkit-scrollbar {
  width: 11px;
}

.admin-offer-body-influencers::-webkit-scrollbar-thumb {
  background: #D6D6FA;
  border-radius: 10px;
}

.admin-offer-body-influencers::-webkit-scrollbar-track {
  background: #EBEAFC;
  border-radius: 10px;
}

.admin-offer-edit-container {
  display: flex;
  margin-top: 78px;

  .admin-offer-edit-filters {
    width: 20%;
  }

  .admin-offer-edit-container-offer-container {
    width: 80%;

    .report-details {
      margin: 0 auto;
      padding: 0;
      width: 888px;
      height: 130px;

      #offer-name-price {
        width: 38px;
        height: 30px;
        font-family: Geometria;
        font-size: 26px;
        font-weight: 900;
        text-align: center;
        color: #3330e4;
      }

      #offer-description {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }

      p {
        font-family: Geometria;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
      }

      .report-details-first, .report-details-second, .report-details-third {
        padding: 0;
        margin: 0;
      }

      .report-details-first, .report-details-third {
        padding-top: 25px;
      }
    }

    .admin-offer-edit-table-container {
      margin-top: 79px;

      .admin-table {
        width: 70%;
        margin: 0 auto;

        .admin-table-header {
          #imgChooseHeader {
            padding: 2px;
            border: 2px solid white;
            border-radius: 5px;
            width: 15px;
            height: 15px;
            margin: 0 auto;
          }
        }
      }
    }
  }

}

.pagination-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 70px;
}

.arrow {
  font-size: 48px;
  color: #007bff;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
  margin-bottom: 4px;
}

.arrow:hover {
  color: #0056b3;
  transform: scale(1.2);
}

.page-numbers {
  display: flex;
  gap: 10px;
}

.page-button {
  padding: 8px 16px;
  border: 1px solid #007bff;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-family: Geometria;
  font-size: 20px;
  font-weight: 400;
}

.page-button.active {
  font-weight: 700;
}

.page-button:hover {
  transform: scale(1.1);
}

.genres-countries-categories-container {
  margin: 60px auto 0;
  display: flex;
  justify-content: space-between;
  width: 60%;
  gap: 40px;

  .block {
    #title {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 800;
      color: blue;
      margin: 0;
    }

    p {
      font-family: Geometria;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .checkbox {
      display: flex;
      align-items: center;
      font-family: Geometria;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 7px;
      position: relative;
    }

    .checkbox input[type="checkbox"] {
      appearance: none;
      width: 17px;
      height: 17px;
      border: 2px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
    }

    .checkbox input[type="checkbox"]:checked {
      background-color: blue;
      border-color: blue;
    }

    .checkbox input[type="checkbox"]:checked::after {
      content: '';
      background-image: url("../../images/vector.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 80%;
      transform: translate(-50%, -50%);
    }

    .checkbox label {
      margin-left: 10px;
      padding-top: 2px;
    }

    .countries-container {
      .country {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 60px;
        margin-top: -50px;

        span {
          font-family: Geometria;
          font-size: 24px;
          width: 24px;
          font-weight: 800;
          text-align: left;
          color: blue;
          margin-top: 100px;
          margin-right: 10px;
        }
      }
    }
  }

  .categories {
    .block {
      width: 50%;

      #title {
        font-family: Geometria;
        font-size: 24px;
        font-weight: 800;
        color: blue;
        margin: 0;
      }

      p {
        font-family: Geometria;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .checkbox {
        display: flex;
        align-items: center;
        font-family: Geometria;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 7px;
        position: relative;
      }

      .checkbox input[type="checkbox"] {
        appearance: none;
        width: 17px;
        height: 17px;
        border: 2px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
      }

      .checkbox input[type="checkbox"]:checked {
        background-color: blue;
        border-color: blue;
      }

      .checkbox input[type="checkbox"]:checked::after {
        content: '';
        background-image: url("../../images/vector.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        height: 80%;
        transform: translate(-50%, -50%);
      }

      .checkbox label {
        margin-left: 10px;
        padding-top: 2px;
      }
    }
  }
}

.admin-influencers-history {
  .admin-influencers-history-search-container {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-family: Geometria;
      font-size: 25px;
      font-weight: 800;
      color: blue;
      text-align: center !important;
    }

    .admin-influencers-platforms-container {
      margin-top: 20px;
    }
    
    .admin-influencers-history-search {
      margin-top: 40px;
    }
  }
}