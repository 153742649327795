.input-wrapper {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100%;
    border-radius: 30px;
    border: 1px solid #3330E4;
}

input {
    background-color: transparent;
    border: none;
    margin-left: 4px;
    margin-top: 2px;
    width: 426px;
    height: 18px;
    font-family: Geometria;
    font-size: 18px;
    font-weight: 500;
    line-height: 22.64px;
    text-align: left;
    color: #000000;
}

input:focus {
    outline: none;
}

input::placeholder {
    color: #000000;
    opacity: 1;
}

.search-icon {
    width: 20px;
    height: 20px;
}